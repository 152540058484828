<script setup lang="ts">

import {ref} from "vue";

const listSoft = ref([
    "All"
]);

const showOption = ref(false);

const currSport = ref("All");

const changeSport = (value: string) => {
    currSport.value = value;
    showOption.value = false;
}

const keyword = ref("");

const search = () => {
    location.href = `/search?q=${keyword.value}`
}

</script>

<template>
<div c2c-0 class="w-100% p-6-20 rm bg-0">
    <div c2c-1 class="bg-0-l:-10 h-40 rm p-12 br-5-0-0-5">
        <div c2c-2 class="bd-r p-r-12 fs-15 relative">
            <p c2c-3 class="rm usn cur-1" @click="showOption = !showOption">
                {{currSport}}
                <span c2c-4 class="icon-down-open"></span>
            </p>
            <ul c2c-5 class="absolute t-30 br-6 l--12 bg-0-l:-10 bd
                       ^ >li p-12 bd-b cur-1
                      " v-show="showOption">
                <li v-for="s in listSoft" @click="changeSport(s)">
                    {{s}}
                </li>
            </ul>
        </div>
    </div>
    <input c2c-6 class="search-input w-100% h-40 bd-w:0 bg-0-l:-10 br-0 :h bg-0-l:-10 bd-w:0"
           @change="search"
           v-model="keyword"
           type="text" placeholder="Search for banners">
    <p c2c-7 class="icon-search bg-0-l:-10 h-40 rm fs-14 p-lr-12 br-0-5-5-0"></p>

</div>
</template>

<style scoped>
.search-input {
    outline: none;
    &:focus {
        outline: none;
    }

}

</style><style lang="scss" scoped>.w-100\%[c2c-0] {
                        width: 100%;
                 }.p-6-20[c2c-0] {
                        padding-top: 6px;padding-right: 20px;padding-bottom: 6px;padding-left: 20px;
                 }.rm[c2c-0] {
                        display: flex; flex-direction: row; align-items: center;
                 }.bg-0[c2c-0] {
                        background-color: hsl(0, 0%, 100%);
                 }.bg-0-l\:-10[c2c-1] {
                        background-color: hsl(0, 0%, 90%);
                 }.h-40[c2c-1] {
                        height: 40px;
                 }.rm[c2c-1] {
                        display: flex; flex-direction: row; align-items: center;
                 }.p-12[c2c-1] {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }.br-5-0-0-5[c2c-1] {
                        border-top-left-radius: 5px;border-top-right-radius: 0;border-bottom-right-radius: 0;border-bottom-left-radius: 5px;
                 }.bd-r[c2c-2] {
                        border-right-width: 1px;border-right-style: solid;border-right-color: hsla(0, 0%, 72.5%, 0.7);
                 }.p-r-12[c2c-2] {
                        padding-right: 12px;
                 }.fs-15[c2c-2] {
                        font-size: 15px;
                 }.relative[c2c-2] {
                        position: relative;
                 }.rm[c2c-3] {
                        display: flex; flex-direction: row; align-items: center;
                 }.usn[c2c-3] {
                        user-select: none;
                 }.cur-1[c2c-3] {
                        cursor: pointer;
                 }.absolute[c2c-5] {
                        position: absolute;
                 }.t-30[c2c-5] {
                        
            top: 30px;
        
                 }.br-6[c2c-5] {
                        border-top-left-radius: 6px;border-top-right-radius: 6px;border-bottom-right-radius: 6px;border-bottom-left-radius: 6px;
                 }.l--12[c2c-5] {
                        
            left: -12px;
        
                 }.bg-0-l\:-10[c2c-5] {
                        background-color: hsl(0, 0%, 90%);
                 }.bd[c2c-5] {
                        border-width: 1px; border-style: solid; border-color: hsla(0, 0%, 72.5%, 0.7)
                 }.\>li.p-12[c2c-5] > li {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }.\>li.bd-b[c2c-5] > li {
                        border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: hsla(0, 0%, 72.5%, 0.7);
                 }.\>li.cur-1[c2c-5] > li {
                        cursor: pointer;
                 }.w-100\%[c2c-6] {
                        width: 100%;
                 }.h-40[c2c-6] {
                        height: 40px;
                 }.bd-w\:0[c2c-6] {
                        border-top-width: 0;border-right-width: 0;border-bottom-width: 0;border-left-width: 0;
                 }.bg-0-l\:-10[c2c-6] {
                        background-color: hsl(0, 0%, 90%);
                 }.br-0[c2c-6] {
                        border-top-left-radius: 0;border-top-right-radius: 0;border-bottom-right-radius: 0;border-bottom-left-radius: 0;
                 }.\:h.bg-0-l\:-10[c2c-6]:hover {
                        background-color: hsl(0, 0%, 90%);
                 }.\:h.bd-w\:0[c2c-6]:hover {
                        border-top-width: 0;border-right-width: 0;border-bottom-width: 0;border-left-width: 0;
                 }.bg-0-l\:-10[c2c-7] {
                        background-color: hsl(0, 0%, 90%);
                 }.h-40[c2c-7] {
                        height: 40px;
                 }.rm[c2c-7] {
                        display: flex; flex-direction: row; align-items: center;
                 }.fs-14[c2c-7] {
                        font-size: 14px;
                 }.p-lr-12[c2c-7] {
                        padding-left: 12px;padding-right: 12px;
                 }.br-0-5-5-0[c2c-7] {
                        border-top-left-radius: 0;border-top-right-radius: 5px;border-bottom-right-radius: 5px;border-bottom-left-radius: 0;
                 }</style>