

const UpdateClientMetaSEO = (seo:any)=>{
    if(!$) return;
    $('meta[name="keywords"]').attr('content', seo.keywords);
    $('meta[name="description"]').attr('content', seo.description);
    $('#json-ld').text(seo.json_ld);
    if (typeof document !== 'undefined' && seo.title) {
        document.title = seo.title;
    }
}

export {UpdateClientMetaSEO};