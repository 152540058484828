<script setup lang="ts">
import '../../class2css/assets/css/style.scss';
if(import.meta.env.DEV) import('../../class2css/dev/style.css');
import MyHeader from "../../core/cpn/web/layout/MyHeader.vue";
import {CustomerSignInStatus, WebPopup} from "lct-lib";
import {onMounted, ref} from "vue";
import MyFooter from "../../core/cpn/web/layout/MyFooter.vue";
import {customerStore} from "./customer.store";
import {storeToRefs} from "pinia";
import {useCartStore} from "./cart.store";

const client = ref(false);
//const {customer} = storeToRefs(customerStore());
const {load} = customerStore();
const {load: loadCart} = useCartStore();


onMounted(async () => {
    client.value = true;
    await load();
    await loadCart();
    // try {
    //     const result:any = await CustomerSignInStatus();
    //     customer.value = result.data;
    // } catch (e) {
    //
    // }
})

</script>

<template>
<div c2c-0 class="">
    <my-header></my-header>
    <slot></slot>
    <my-footer></my-footer>
    <web-popup v-if="client"></web-popup>

</div>
</template>

<style scoped>

</style><style lang="scss" scoped></style>