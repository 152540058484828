<script setup lang="ts">

import {computed, ref} from "vue";

const elm = ref(null);
const menus = computed(()=>{
    const root = __MAIN_MENU__.navigator.filter(lv1 => lv1.active);
    for (const lv1 of root) {
        if(lv1.child) {
            lv1.child = lv1.child.filter(lv2 => lv2.active);
        }
    }
    return root;

})

const hideMenu = ()=>{
    elm.value.classList.add("child-hide");
}

const showMenu = ()=>{
    elm.value.classList.remove("child-hide");
}

</script>

<template>
    <ul ref="elm" c2c-0 class="menu" v-if="menus">
        <li v-for="item in menus">
            <a :href="item.link" @mouseover="showMenu" @click="hideMenu">
                <img v-if="item.icon" :src="item.icon" :alt="item.label">
                <span c2c-1 class="ws-nowrap">{{ item.label }}</span>
            </a>
            <ul v-if="item.child">
                <li v-for="child in item.child">
                    <a :href="child.link" @click="hideMenu">
                        {{child.label}}
                    </a>
                </li>
            </ul>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
@keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.menu {
    li {
        &:hover {
            a {
                img {
                    animation: spin 5s linear infinite;
                }
            }
            ul {
                display: block;
            }
        }
    }
    &.child-hide {
        li {
            ul {
                display: none;
            }
        }
    }
}
</style><style lang="scss" scoped>.ws-nowrap[c2c-1] {
                        white-space: nowrap;
                 }</style>