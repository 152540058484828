<script setup lang="ts">

import Menu from "./Menu.vue";
import Search from "./Search.vue";
import {CustomerSignOut, useWebPopup, WebPanel} from "lct-lib";
import {onMounted, ref} from "vue";
import {storeToRefs} from "pinia";
import {customerStore} from "../../../../pages/(web)/customer.store";

const isShowMenu = ref(false);

const {show} = useWebPopup();

const {customer, isFirstLoad} = storeToRefs(customerStore());

onMounted(()=>{
})

const signOut = async () => {
    try {
        const result = await CustomerSignOut();
        show("You have successfully logged out.", ()=>{
            location.href = "/";
        });

    } catch (e) {

    }
}

</script>

<template>
    <div c2c-0 class="w-100%">
        <div c2c-1 class="header h-60 rw rm bg-header c-0 sd">
            <div c2c-2 class="h-60 p-6 m-l-30 <2 m-l-6">
                <a c2c-3 class="block" href="/"><img c2c-4 class="w-79" src="/logo/logo.svg" alt="logo teamsbanners"/></a>
            </div>
            <div c2c-5 class="w-100% h-100% p-l-50 <2 none">
                <Menu c2c-6 class="menu-d"></Menu>
            </div>
            <div c2c-7 class="p-lr-12 m-r-30 <3 m-r-6">
                <ul c2c-8 class="rm >li ws-nowrap <3 none ^ >>a c-0">

                    <li v-if="!customer && !isFirstLoad" c2c-9 class="rm fs-14 >>a c-0 >>a:h u">
                        <a c2c-10 class="fs-14" href="/order-detail">Track Order</a>
                        <span c2c-11 class="m-lr-6 fs-10">|</span>
                        <a href="/sign-in">Sign In</a>
                        <span c2c-12 class="m-lr-6 fs-10">|</span>
                        <a href="/sign-up">Sign Up</a>
                    </li>
                    <li v-else-if="customer" c2c-13 class="relative h-60 rm
                                    ^ :h bg-header-l:10
                                    ^ :h>ul block">
                        <a c2c-14 class="p-0-12" href="/account">
                            <span c2c-15 class="icon-user c-0 fs-22"></span>
                            {{customer.name || "My Account"}}
                        </a>
                        <ul c2c-16 class="absolute none bg-header-l:10 br-0-0-5-5 t-60 z-100000
                                   ^ >li:h bg-header-l:20
                                   ^ >li>* p-12-31 block">
                            <li>
                                <a href="/my-account">My account</a>
                            </li>
                            <li>
                                <a href="/my-orders">My orders</a>
                            </li>
                            <li>
                                <a href="/order-detail">Track order</a>
                            </li>
                            <li>
                                <p c2c-17 class="cur-1" @click="signOut">Sign out</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a c2c-18 class=":h>* c-logo" href="/cart">
                            <span c2c-19 class="icon-cart c-0 fs-21 m-l-30"></span>
                        </a>
                    </li>
                </ul>


                <ul c2c-20 class="rm c-0 >3 none">
                    <li>
                        <a href="/cart">
                            <span c2c-21 class="icon-cart c-0 fs-21 m-r-12"></span>
                        </a>
                    </li>
                    <li>
                        <span c2c-22 class="icon-menu-3 c-0 fs-30" @click="isShowMenu = !isShowMenu"></span>
                    </li>
                </ul>

            </div>
        </div>
        <div c2c-23 class="h-60 rm bd-b bg-0">
            <Search></Search>
        </div>
    </div>
    <web-panel v-model="isShowMenu" position="right" w="80%" :over="false" :AClose="true">
        <a c2c-24 class="m-tb-12 block" href="/">
            <span c2c-25 class="icon-home fs-22 p-l-6 m-r-8"></span>
            <span c2c-26 class="ff2 fs-18 tb">Home</span>
        </a>
        <Menu c2c-27 class="menu-m"></Menu>
        <div v-if="!customer" c2c-28 class="ff2 p-12 rc">
            <a href="/order-detail">Track order</a>
            <span c2c-29 class="m-lr-12">|</span>
            <a href="/sign-in">Sign in</a>
            <span c2c-30 class="m-lr-12">|</span>
            <a href="/sign-up">Sign up</a>
        </div>
        <div v-else>
            <a c2c-31 class="m-tb-12 block" href="/my-account">
                <span c2c-32 class="icon-user fs-22 p-l-6 m-r-8"></span>
                <span c2c-33 class="ff2 fs-18 tb">{{customer.name}} | My account</span>
            </a>
            <a c2c-34 class="m-tb-12 block" href="/order-detail">
                <span c2c-35 class="p-l-45"></span>
                <span c2c-36 class="ff2 fs-18 tb">Track order</span>
            </a>
            <a c2c-37 class="m-tb-12 block" href="/my-orders">
                <span c2c-38 class="p-l-45"></span>
                <span c2c-39 class="ff2 fs-18 tb">My orders</span>
            </a>
        </div>
    </web-panel>



</template>

<style scoped>

</style><style lang="scss" scoped>.w-100\%[c2c-0] {
                        width: 100%;
                 }.h-60[c2c-1] {
                        height: 60px;
                 }.rw[c2c-1] {
                        display: flex; flex-direction: row; justify-content: space-between;
                 }.rm[c2c-1] {
                        display: flex; flex-direction: row; align-items: center;
                 }.bg-header[c2c-1] {
                        background-color: hsl(226.10000000000002, 45.4%, 15%);
                 }.c-0[c2c-1] {
                        color: hsl(0, 0%, 100%);
                 }.sd[c2c-1] {
                        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2)
                 }.h-60[c2c-2] {
                        height: 60px;
                 }.p-6[c2c-2] {
                        padding-top: 6px;padding-right: 6px;padding-bottom: 6px;padding-left: 6px;
                 }.m-l-30[c2c-2] {
                        margin-left: 30px;
                 }@media (max-width: 768px) {.\<2.m-l-6[c2c-2] {
                        margin-left: 6px;
                 }}.block[c2c-3] {
                        display: block;
                 }.w-79[c2c-4] {
                        width: 79px;
                 }.w-100\%[c2c-5] {
                        width: 100%;
                 }.h-100\%[c2c-5] {
                        height: 100%;
                 }.p-l-50[c2c-5] {
                        padding-left: 50px;
                 }@media (max-width: 768px) {.\<2.none[c2c-5] {
                        display: none;
                 }}.p-lr-12[c2c-7] {
                        padding-left: 12px;padding-right: 12px;
                 }.m-r-30[c2c-7] {
                        margin-right: 30px;
                 }@media (max-width: 992px) {.\<3.m-r-6[c2c-7] {
                        margin-right: 6px;
                 }}.rm[c2c-8] {
                        display: flex; flex-direction: row; align-items: center;
                 }.\>li.ws-nowrap[c2c-8] > li {
                        white-space: nowrap;
                 }@media (max-width: 992px) {.\<3.\>li.none[c2c-8] > li {
                        display: none;
                 }}.\>\>a.c-0[c2c-8] a {
                        color: hsl(0, 0%, 100%);
                 }.rm[c2c-9] {
                        display: flex; flex-direction: row; align-items: center;
                 }.fs-14[c2c-9] {
                        font-size: 14px;
                 }.\>\>a.c-0[c2c-9] a {
                        color: hsl(0, 0%, 100%);
                 }.\>\>a\:h.u[c2c-9] a:hover {
                        text-decoration: underline;
                 }.fs-14[c2c-10] {
                        font-size: 14px;
                 }.m-lr-6[c2c-11] {
                        margin-left: 6px;margin-right: 6px;
                 }.fs-10[c2c-11] {
                        font-size: 10px;
                 }.m-lr-6[c2c-12] {
                        margin-left: 6px;margin-right: 6px;
                 }.fs-10[c2c-12] {
                        font-size: 10px;
                 }.relative[c2c-13] {
                        position: relative;
                 }.h-60[c2c-13] {
                        height: 60px;
                 }.rm[c2c-13] {
                        display: flex; flex-direction: row; align-items: center;
                 }.\:h.bg-header-l\:10[c2c-13]:hover {
                        background-color: hsl(226.10000000000002, 45.4%, 25%);
                 }.\:h\>ul.block[c2c-13]:hover > ul {
                        display: block;
                 }.p-0-12[c2c-14] {
                        padding-top: 0;padding-right: 12px;padding-bottom: 0;padding-left: 12px;
                 }.c-0[c2c-15] {
                        color: hsl(0, 0%, 100%);
                 }.fs-22[c2c-15] {
                        font-size: 22px;
                 }.absolute[c2c-16] {
                        position: absolute;
                 }.none[c2c-16] {
                        display: none;
                 }.bg-header-l\:10[c2c-16] {
                        background-color: hsl(226.10000000000002, 45.4%, 25%);
                 }.br-0-0-5-5[c2c-16] {
                        border-top-left-radius: 0;border-top-right-radius: 0;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;
                 }.t-60[c2c-16] {
                        
            top: 60px;
        
                 }.z-100000[c2c-16] {
                        z-index: 100000;
                 }.\>li\:h.bg-header-l\:20[c2c-16] > li:hover {
                        background-color: hsl(226.10000000000002, 45.4%, 35%);
                 }.\>li\>\*.p-12-31[c2c-16] > li > * {
                        padding-top: 12px;padding-right: 31px;padding-bottom: 12px;padding-left: 31px;
                 }.\>li\>\*.block[c2c-16] > li > * {
                        display: block;
                 }.cur-1[c2c-17] {
                        cursor: pointer;
                 }.\:h\>\*.c-logo[c2c-18]:hover > * {
                        color: hsl(197.39999999999998, 95.9%, 38%);
                 }.c-0[c2c-19] {
                        color: hsl(0, 0%, 100%);
                 }.fs-21[c2c-19] {
                        font-size: 21px;
                 }.m-l-30[c2c-19] {
                        margin-left: 30px;
                 }.rm[c2c-20] {
                        display: flex; flex-direction: row; align-items: center;
                 }.c-0[c2c-20] {
                        color: hsl(0, 0%, 100%);
                 }@media (min-width: 992px) {.\>3.none[c2c-20] {
                        display: none;
                 }}.c-0[c2c-21] {
                        color: hsl(0, 0%, 100%);
                 }.fs-21[c2c-21] {
                        font-size: 21px;
                 }.m-r-12[c2c-21] {
                        margin-right: 12px;
                 }.c-0[c2c-22] {
                        color: hsl(0, 0%, 100%);
                 }.fs-30[c2c-22] {
                        font-size: 30px;
                 }.h-60[c2c-23] {
                        height: 60px;
                 }.rm[c2c-23] {
                        display: flex; flex-direction: row; align-items: center;
                 }.bd-b[c2c-23] {
                        border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: hsla(0, 0%, 72.5%, 0.7);
                 }.bg-0[c2c-23] {
                        background-color: hsl(0, 0%, 100%);
                 }.m-tb-12[c2c-24] {
                        margin-top: 12px;margin-bottom: 12px;
                 }.block[c2c-24] {
                        display: block;
                 }.fs-22[c2c-25] {
                        font-size: 22px;
                 }.p-l-6[c2c-25] {
                        padding-left: 6px;
                 }.m-r-8[c2c-25] {
                        margin-right: 8px;
                 }.fs-18[c2c-26] {
                        font-size: 18px;
                 }.tb[c2c-26] {
                        font-weight: bold;
                 }.p-12[c2c-28] {
                        padding-top: 12px;padding-right: 12px;padding-bottom: 12px;padding-left: 12px;
                 }.rc[c2c-28] {
                        display: flex; flex-direction: row; justify-content: center;
                 }.m-lr-12[c2c-29] {
                        margin-left: 12px;margin-right: 12px;
                 }.m-lr-12[c2c-30] {
                        margin-left: 12px;margin-right: 12px;
                 }.m-tb-12[c2c-31] {
                        margin-top: 12px;margin-bottom: 12px;
                 }.block[c2c-31] {
                        display: block;
                 }.fs-22[c2c-32] {
                        font-size: 22px;
                 }.p-l-6[c2c-32] {
                        padding-left: 6px;
                 }.m-r-8[c2c-32] {
                        margin-right: 8px;
                 }.fs-18[c2c-33] {
                        font-size: 18px;
                 }.tb[c2c-33] {
                        font-weight: bold;
                 }.m-tb-12[c2c-34] {
                        margin-top: 12px;margin-bottom: 12px;
                 }.block[c2c-34] {
                        display: block;
                 }.p-l-45[c2c-35] {
                        padding-left: 45px;
                 }.fs-18[c2c-36] {
                        font-size: 18px;
                 }.tb[c2c-36] {
                        font-weight: bold;
                 }.m-tb-12[c2c-37] {
                        margin-top: 12px;margin-bottom: 12px;
                 }.block[c2c-37] {
                        display: block;
                 }.p-l-45[c2c-38] {
                        padding-left: 45px;
                 }.fs-18[c2c-39] {
                        font-size: 18px;
                 }.tb[c2c-39] {
                        font-weight: bold;
                 }</style>