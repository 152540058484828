import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {GetLocalStorage, SetLocalStorage} from "lct-lib";
import {ICart} from "../../core/interface/ICart";


const useCartStore = defineStore("cart", ()=>{
    const cart:any = ref<ICart>({
        items: [],
        ship_cost: "0.00",
        bill: {
            country: "United States"
        },
        ship: {
            country: "United States"
        }
    });

    const load = async () => {
        const result:any = GetLocalStorage("cart");
        if(result) {
            cart.value.items = result;
        }
        else {
            cart.value.items = [];
        }
    }
    const removeItem = (at: number)=>{
        cart.value.items.splice(at,1);
        SetLocalStorage("cart", cart.value.items);
    }
    const addItem = (item: any)=>{
        cart.value.items.push(item);
        SetLocalStorage("cart", cart.value.items);
    }
    /**
     * Save to local storage
     */
    const saveItemInCart = ()=>{
        SetLocalStorage("cart", cart.value.items);
    }

    const subtotal = computed(()=>{
        let sum = 0;
        if(!cart.value) return 0;

        for (const item of cart.value.items) {
            sum += Number(item.sale_price) * Number(item.qty);
            if(item.option && item.option.price) {
                sum += Number(item.option.price);
            }
        }
        cart.value.subtotal = sum.toFixed(2);
        return cart.value.subtotal;
    })
    const tax = computed(()=>{
        if(!cart.value) return 0;
        cart.value.tax = (Number(subtotal.value) * (7.25 / 100)).toFixed(2);
        return cart.value.tax;
    })

    const shipCost = computed(()=>{
        if(cart.value && cart.value.ship_method) {
            cart.value.ship_cost = cart.value.ship_method.value.toFixed(2);
            return cart.value.ship_cost;
        } else {
            return 0;
        }
    })

    const total = computed(()=>{
        let sum =  Number(subtotal.value) + Number(tax.value) + Number(shipCost.value);
        cart.value.total = sum.toFixed(2);
        return cart.value.total;
    })

    /**
     * Validate cart
     */
    const isValid = computed(()=>{
        if (
            cart.value.bill.firstname && cart.value.bill.firstname.trim() !== '' &&
            cart.value.bill.lastname && cart.value.bill.lastname.trim() !== '' &&
            cart.value.bill.address && cart.value.bill.address.trim() !== '' &&
            cart.value.bill.city && cart.value.bill.city.trim() !== '' &&
            cart.value.bill.state && cart.value.bill.state.trim() !== '' &&
            cart.value.bill.postal_code && cart.value.bill.postal_code.trim() !== '' &&
            cart.value.bill.country && cart.value.bill.country.trim() !== '' &&
            cart.value.bill.phone && cart.value.bill.phone.trim() !== '' &&
            cart.value.bill.email && cart.value.bill.email.trim() !== '' &&

            cart.value.ship.firstname && cart.value.ship.firstname.trim() !== '' &&
            cart.value.ship.lastname && cart.value.ship.lastname.trim() !== '' &&
            cart.value.ship.address && cart.value.ship.address.trim() !== '' &&
            cart.value.ship.city && cart.value.ship.city.trim() !== '' &&
            cart.value.ship.state && cart.value.ship.state.trim() !== '' &&
            cart.value.ship.postal_code && cart.value.ship.postal_code.trim() !== '' &&
            cart.value.ship.country && cart.value.ship.country.trim() !== '' &&
            cart.value.ship.phone && cart.value.ship.phone.trim() !== '' &&
            cart.value.ship.email && cart.value.ship.email.trim() !== '' &&

            cart.value.ship_method && cart.value.ship_method.label !== "" &&
            cart.value.email && cart.value.email.trim() !== ''
        ) {
            return true
        } else
        return false
    })


    return {cart, load, subtotal, tax, total, removeItem, shipCost, saveItemInCart, isValid, addItem}
})

export {useCartStore}