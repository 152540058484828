import {UpdateClientMetaSEO} from "../../core/utils/UpdateClientMetaSEO";

const onAfterRenderClient = (pageContext: any)=>{
    UpdateClientMetaSEO({
        keywords: pageContext.data?.keywords || "",
        description: pageContext.data?.description || "",
        json_ld: pageContext.data?.json_ld || "",
        title: pageContext.data?.title || "",
    })

    if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('event', 'page_view', {
            page_path: window.location.pathname,
            page_title: document.title,
        });
        //console.log("title: ",document.title);
    }

}

export {onAfterRenderClient}