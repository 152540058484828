import {defineStore, storeToRefs} from "pinia";
import {ref} from "vue";
import {CustomerSignInStatus, ICustomer} from "lct-lib";
import {useCartStore} from "./cart.store";


const customerStore = defineStore("customer", ()=>{
    const customer = ref<ICustomer>();
    const isFirstLoad = ref(true);
    const {cart} = storeToRefs(useCartStore());

    const load = async () => {
        const result:any = await CustomerSignInStatus();
        customer.value = result.data;
        if(result.data.byFieldValue) {
            cart.value.email = result.data.byFieldValue;
        }
        isFirstLoad.value = false;
    }

    return {customer, load, isFirstLoad}
})

export {customerStore}